import {
  CardActionArea,
  CardContent, Skeleton,
  Typography,
} from '@mui/material';
import dynamic from 'next/dynamic';
import PropTypes from "prop-types";
import ButtonPrimary from "components/atoms/Button";
import { useLocation } from "../../lib/useLocation";
import Image from "next/image";
import { useState } from "react";
import Card from "@mui/material/Card";

const DCustomIcon = dynamic(() => import('../atoms/CustomIcon'),{
  loading: () => (
    <Skeleton
      animation="wave"
      variant="rectangular"
      height='20px'
      width="20px"
      className="pl-1"
    />
  ),
  ssr: false
});

export default function Cards({
                                title,
                                image,
                                path,
                                price,
                                state,
                                property,
                                url,
                                restroom,
                                bedroom,
                                parking,
                                size,
                                unit,
                                treatment,
                                sx,
                                index = 0
                              }) {
  const [src, setSrc] = useState(image[0]?.uri || "/img/no-image-available.svg");

  const home = useLocation();
  const measure = unit === "ha" ? ('ha') : (<span>m<sup>2</sup></span>);
  const treatmentBgColor = treatment === "Venta" ? "bg-green" : "bg-red";
  index = index + 1;
  return (
    <Card sx={sx}>
      <CardActionArea sx={{ "&.MuiCardActionArea-root": { height: "14rem", width: "100%", position: "relative" } }} target="_blank" className="overflow-hidden" href={path}>
        <Image
          priority={index < 3}
          className="brightness-75 h-[243px] object-cover"
          src={src}
          alt={image[0]?.alt || 'no image available'}
          loading={index < 3 ? "eager" : "lazy"}
          placeholder="blur"
          blurDataURL="/img/no-image-available.svg"
          onError={() => setSrc('/img/no-image-available.svg')}
          fill
          sizes="100vw"
          style={{
            objectFit: "cover",
            objectPosition: "center"
          }} />
        {treatment ?
          (<p className={'p-1 absolute top-3 left-3 font-extrabold px-2 text-white ' + treatmentBgColor}>{treatment}</p> || "") : (" ")
        }
        <div className="absolute bottom-5 md:bottom-3 left-3 text-xl font-bold text-white">
          {property || ""}
          <br/>
          {price || ""}
        </div>
      </CardActionArea>
      <CardContent className={`${home ? "p-0 py-3" : "m-1"} font-bold`}>
        <Typography className="line-clamp-2 leading-tight text-black !text-xl" gutterBottom variant="h3">
            {title}
          </Typography>
          {<p className="text-gray-soft">{state}</p> || ""}
          <div className={`${home ? "" : "min-h-[7rem] justify-end"} flex flex-col gap-4 py-3 text-gray`}>
            {restroom || parking || bedroom || size ? (
              <ul className="flex flex-wrap h-6 gap-2 text-black">
                {restroom ? (<li className="flex items-center gap-1 bg-gray-smooth rounded px-1"> {restroom}
                <DCustomIcon iconName="toilet" viewBox="0 0 800 800" className="text-sm text-green-soft"/></li>): ""}
                {parking ? (
                  <li className="flex items-center gap-1 bg-gray-smooth rounded px-1">
                    {parking}
                    <DCustomIcon iconName="car" viewBox="0 0 1000 800" />
                  </li>):""}
                {bedroom ? (
                  <li className="flex items-center gap-1 bg-gray-smooth rounded px-1">
                    {bedroom}
                    <DCustomIcon iconName="hotel" viewBox="0 0 800 800" className="text-xl text-green-soft"/>
                  </li>
                ):""}
                {size ? (
                  <li className="flex items-center gap-1 bg-gray-smooth rounded px-1">
                    <span>{size}{measure}</span>
                    <DCustomIcon iconName="ruler" viewBox="0 0 800 800" className="text-base text-green-soft"/>
                  </li>):""}
              </ul>
            ) : (
              <ul className="flex flex-wrap h-6 gap-2 text-black">
              </ul>
            )}
            {url && (
              <div className={`w-fit h-12 self-center mt-6`}>
                <ButtonPrimary
                  url={url}
                  backgroundButton="#B12028"
                  colorTextButton="#FFFFFF"
                  textButton="Contactar"
                  endIcon={<DCustomIcon iconName="chevronRight" viewBox="0 0 40 50" className="!text-lg text-white"/>}
                />
              </div>
            )}
          </div>
        </CardContent>
    </Card>
  );
}

Cards.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  path: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  municipality: PropTypes.string,
  state: PropTypes.string,
  property: PropTypes.string,
  url: PropTypes.string,
  restroom: PropTypes.number,
  bedroom: PropTypes.number,
  parking: PropTypes.number,
};
