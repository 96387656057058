import { absoluteUrl } from "lib/utils";


export default function formatNodeImages (node, linkFieldName, imageFieldName, size) {
  // Self link images
  const imagesByLink = node[linkFieldName]?.map(link => {
    return getFormatImage(
      getNewUrlImageSize(link.uri, size),
      node.title
    );
  });
  // Drupal images
  const imagesByFieldImage = node[imageFieldName]?.map(imagen => {
    return getFormatImage(
      absoluteUrl(imagen.field_media_image?.uri.url),
      imagen.field_media_image?.resourceIdObjMeta.alt
    );
  });
  return imagesByFieldImage.concat(imagesByLink);
}

function getFormatImage (uri, alt = "") {
  return {
    uri,
    alt
  }
}

function getNewUrlImageSize(uri, size) {
  if(!size || !uri.includes("https://s3.us-east-1.amazonaws.com/tcmc-images") ) return uri;
  const newUri = uri.slice(0, uri.indexOf(".webp")) + uri.slice(uri.indexOf(".webp"));
  return newUri;
}
